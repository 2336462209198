<template>
  <div v-if="showHeader">
    <header class="header" :class="authRoute ? 'header--login' : ''">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <nav class="header-nav" :class="{ 'header-nav--with-org-logo': showHeaderLogoOnMobile }">
              <a
                v-if="authRoute"
                class="header-logo header-logo--desktop"
                :class="{ 'header-logo--show-on-mobile': showHeaderLogoOnMobile }"
                :href="marketingSiteBaseUrl"
                rel="noopener noreferrer"
                target="_blank"
              >
                <TheOrganizationLogo height="12" inverse="inverse" />
              </a>
              <RouterLink
                v-else
                class="header-logo header-logo--desktop"
                :class="{ 'header-logo--show-on-mobile': showHeaderLogoOnMobile }"
                to="/"
              >
                <TheOrganizationLogo />
              </RouterLink>

              <div v-if="authRoute" class="header-nav__login">
                <div class="header-nav__login-left">
                  <a
                    class="header-nav__link"
                    :href="`${marketingSiteBaseUrl}/about-us`"
                    rel="noopener noreferrer"
                    target="_blank"
                    >About</a
                  ><a
                    class="header-nav__link"
                    :href="`${marketingSiteBaseUrl}/memberships-options`"
                    rel="noopener noreferrer"
                    target="_blank"
                    >Membership</a
                  ><a
                    class="header-nav__link"
                    :href="`${marketingSiteBaseUrl}/contact`"
                    rel="noopener noreferrer"
                    target="_blank"
                    >Contact</a
                  >
                </div>
                <div class="header-nav__login-right">
                  <a
                    class="header-nav__link header-nav__link--right"
                    :href="`${marketingSiteBaseUrl}/memberships-options`"
                    rel="noopener noreferrer"
                    target="_blank"
                    >Join Us</a
                  >
                </div>
              </div>

              <div v-if="$route.name && $route.name !== 'login'" class="btns header-btns--desktop">
                <template v-if="!isMainSearchModalShown">
                  <button
                    v-if="$route.name !== 'home'"
                    class="d-block d-md-none d-xl-none d-lg-none back-btn position-absolute p-0"
                    type="button"
                    @click="goBack"
                  >
                    <TheArrowHorizIcon />
                  </button>

                  <button v-if="isAuth" class="inbox-btn" @click="showMsgInbox">
                    <InboxMsgIcon />
                    <span v-show="unreadAppMessagesCount" class="inbox-btn__count">{{ unreadAppMessagesCount }}</span>
                  </button>

                  <button v-if="isAuth && showFilterButton" class="filter-btn" @click="showFilter">
                    <TheFilterIcon />
                  </button>

                  <button v-if="isAuth" class="search-btn--desktop" type="button" @click="showSearchModal">
                    <TheSearchIcon />
                  </button>

                  <button
                    v-if="isAuth"
                    aria-controls="appMainMenu"
                    :aria-expanded="isMainMenuShown"
                    aria-label="Toggle navigation"
                    class="menu-btn--desktop"
                    @click="showMainMenu"
                  >
                    <TheMenuHamburgerIcon />
                  </button>
                </template>

                <template v-if="isMainSearchModalShown">
                  <button class="close-search-btn" @click="hideSearchModal">
                    <TheCrossIcon class="default-stroke" />
                  </button>
                </template>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>

    <AppSearch v-model="isMainSearchModalShown" @close="hideSearchModal" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AppConfig from '@/config';

import AppSearch from '@/components/partials/search/AppSearch';
import TheOrganizationLogo from '@/components/logos/TheOrganizationLogo.vue';
import InboxMsgIcon from '@/components/icons/InboxMsgIcon.vue';
import TheArrowHorizIcon from '@/components/icons/TheArrowHorizIcon.vue';
import TheFilterIcon from '@/components/icons/TheFilterIcon.vue';
import TheSearchIcon from '@/components/icons/TheSearchIcon.vue';
import TheMenuHamburgerIcon from '@/components/icons/TheMenuHamburgerIcon.vue';
import TheCrossIcon from '@/components/icons/TheCrossIcon.vue';

export default {
  name: 'AppHeader',
  components: {
    TheCrossIcon,
    TheMenuHamburgerIcon,
    TheSearchIcon,
    TheFilterIcon,
    TheArrowHorizIcon,
    InboxMsgIcon,
    TheOrganizationLogo,
    AppSearch,
  },
  data() {
    return {
      isDataFiltered: true,
      marketingSiteBaseUrl: AppConfig.getAsString('marketingSiteBaseUrl'),
      needFilter: false,
      toggle: false,
    };
  },
  computed: {
    ...mapState([
      'isAuth',
      'isMainMenuShown',
      'isMainSearchModalShown',
      'isMobileScreenExclude768',
      'unreadAppMessagesCount',
    ]),
    authRoute() {
      return (
        this.$route.name === 'login' ||
        this.$route.name === 'activate-account' ||
        this.$route.name === 'forgot-password' ||
        this.$route.name === 'reset-password'
      );
    },
    showHeader() {
      if (this.$route.name === 'page-not-found') {
        return true;
      }
      if (!this.isMobileScreenExclude768) {
        return true;
      }
      if (this.$route.name === 'destination') {
        return false;
      }
      return !this.$route.path.includes('account');
    },
    showHeaderLogoOnMobile() {
      return this.$route.name === 'home';
    },
    showFilterButton() {
      return this.$route.name !== 'home' && this.$route.name !== 'contact-us' && !this.$route.params.slug;
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    showMainMenu() {
      this.$store.dispatch('showMainMenu', true);
    },
    showSearchModal() {
      this.$store.dispatch('showMainSearchModal', true);
    },
    hideSearchModal() {
      this.$store.dispatch('showMainSearchModal', false);
    },
    showFilter() {
      this.$store.dispatch('toggleAppDataFilter', true);
    },
    showMsgInbox() {
      this.$store.dispatch('showMsgInbox', true);
    },
  },
};
</script>

<style lang="scss" scoped>
.back-btn {
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
}
</style>
