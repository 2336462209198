<template>
  <a :href="href" :target="newTab ? '_blank' : undefined" :rel="newTab ? 'noopener noreferrer' : undefined">
    <slot />
  </a>
</template>

<script>
export default {
  name: 'ExternalLink',
  props: {
    href: {
      type: String,
      required: true,
    },
    newTab: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
